import "core-js/modules/es6.array.copy-within";
import "core-js/modules/es6.array.every";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.array.filter";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.from";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.array.index-of";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.array.last-index-of";
import "core-js/modules/es6.array.map";
import "core-js/modules/es6.array.of";
import "core-js/modules/es6.array.reduce";
import "core-js/modules/es6.array.reduce-right";
import "core-js/modules/es6.array.some";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.array.species";
import "core-js/modules/es6.date.now";
import "core-js/modules/es6.date.to-iso-string";
import "core-js/modules/es6.date.to-json";
import "core-js/modules/es6.date.to-primitive";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.function.bind";
import "core-js/modules/es6.function.has-instance";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.map";
import "core-js/modules/es6.math.acosh";
import "core-js/modules/es6.math.asinh";
import "core-js/modules/es6.math.atanh";
import "core-js/modules/es6.math.cbrt";
import "core-js/modules/es6.math.clz32";
import "core-js/modules/es6.math.cosh";
import "core-js/modules/es6.math.expm1";
import "core-js/modules/es6.math.fround";
import "core-js/modules/es6.math.hypot";
import "core-js/modules/es6.math.imul";
import "core-js/modules/es6.math.log1p";
import "core-js/modules/es6.math.log10";
import "core-js/modules/es6.math.log2";
import "core-js/modules/es6.math.sign";
import "core-js/modules/es6.math.sinh";
import "core-js/modules/es6.math.tanh";
import "core-js/modules/es6.math.trunc";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.epsilon";
import "core-js/modules/es6.number.is-finite";
import "core-js/modules/es6.number.is-integer";
import "core-js/modules/es6.number.is-nan";
import "core-js/modules/es6.number.is-safe-integer";
import "core-js/modules/es6.number.max-safe-integer";
import "core-js/modules/es6.number.min-safe-integer";
import "core-js/modules/es6.number.parse-float";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.object.create";
import "core-js/modules/es7.object.define-getter";
import "core-js/modules/es7.object.define-setter";
import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.entries";
import "core-js/modules/es6.object.freeze";
import "core-js/modules/es6.object.get-own-property-descriptor";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.get-own-property-names";
import "core-js/modules/es6.object.get-prototype-of";
import "core-js/modules/es7.object.lookup-getter";
import "core-js/modules/es7.object.lookup-setter";
import "core-js/modules/es6.object.prevent-extensions";
import "core-js/modules/es6.object.is";
import "core-js/modules/es6.object.is-frozen";
import "core-js/modules/es6.object.is-sealed";
import "core-js/modules/es6.object.is-extensible";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.seal";
import "core-js/modules/es6.object.set-prototype-of";
import "core-js/modules/es7.object.values";
import "core-js/modules/es6.promise";
import "core-js/modules/es7.promise.finally";
import "core-js/modules/es6.reflect.apply";
import "core-js/modules/es6.reflect.construct";
import "core-js/modules/es6.reflect.define-property";
import "core-js/modules/es6.reflect.delete-property";
import "core-js/modules/es6.reflect.get";
import "core-js/modules/es6.reflect.get-own-property-descriptor";
import "core-js/modules/es6.reflect.get-prototype-of";
import "core-js/modules/es6.reflect.has";
import "core-js/modules/es6.reflect.is-extensible";
import "core-js/modules/es6.reflect.own-keys";
import "core-js/modules/es6.reflect.prevent-extensions";
import "core-js/modules/es6.reflect.set";
import "core-js/modules/es6.reflect.set-prototype-of";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.flags";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.set";
import "core-js/modules/es6.symbol";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.string.anchor";
import "core-js/modules/es6.string.big";
import "core-js/modules/es6.string.blink";
import "core-js/modules/es6.string.bold";
import "core-js/modules/es6.string.code-point-at";
import "core-js/modules/es6.string.ends-with";
import "core-js/modules/es6.string.fixed";
import "core-js/modules/es6.string.fontcolor";
import "core-js/modules/es6.string.fontsize";
import "core-js/modules/es6.string.from-code-point";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.string.italics";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.string.link";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es7.string.pad-end";
import "core-js/modules/es6.string.raw";
import "core-js/modules/es6.string.repeat";
import "core-js/modules/es6.string.small";
import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.string.strike";
import "core-js/modules/es6.string.sub";
import "core-js/modules/es6.string.sup";
import "core-js/modules/es6.string.trim";
import "core-js/modules/es6.typed.array-buffer";
import "core-js/modules/es6.typed.data-view";
import "core-js/modules/es6.typed.int8-array";
import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.typed.uint8-clamped-array";
import "core-js/modules/es6.typed.int16-array";
import "core-js/modules/es6.typed.uint16-array";
import "core-js/modules/es6.typed.int32-array";
import "core-js/modules/es6.typed.uint32-array";
import "core-js/modules/es6.typed.float32-array";
import "core-js/modules/es6.typed.float64-array";
import "core-js/modules/es6.weak-map";
import "core-js/modules/es6.weak-set";
import "core-js/modules/web.timers";
import "core-js/modules/web.immediate";
import "core-js/modules/web.dom.iterable";
import "regenerator-runtime/runtime";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import * as Sentry from '@sentry/browser';
import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { parse } from 'querystring';
import _ from 'lodash';
import { StripeProvider } from 'react-stripe-elements';
import CONFIG from '@whistle/config';
import SplashScreen from './pages/splash-screen/SplashScreen';
import LoginContainer from './pages/login/containers/LoginContainer';
import CreateUserContainer from './pages/create-user/containers/CreateUserContainer';
import VerifyUserContainer from './pages/verify-user/containers/VerifyUserContainer';
import CompaniesContainer from './pages/companies/containers/CompaniesContainer';
import CreateCompanyContainer from './pages/create-company/containers/CreateCompanyContainer';
import CreateSMSContainer from './pages/create-sms/containers/CreateSMSContainer';
import InviteContainer from './pages/invite/containers/InviteContainer';
import PlansContainer from './pages/plans/containers/PlansContainer';
import PlansExtraContainer from './pages/plans/containers/PlansExtraContainer';
import BillingContainer from './pages/billing/containers/BillingContainer';
import ChangePasswordContainer from './pages/change-password/containers/ChangePasswordContainer';
import ForgotPasswordContainer from './pages/forgot-password/containers/ForgotPasswordContainer';
import ChoiceCodeContainer from './pages/choice-code/containers/ChoiceCodeContainer';
import { AppContext, AppContextProvider } from './AppContext';
import BookingSuiteService from './services/BookingSuite';
import CloudbedsService from './services/Cloudbeds';
import LiveChatService from './services/LiveChat';
import GA from './services/GoogleAnalytics';
import Storage from './services/Storage';
import './styles/main.scss';
Sentry.init(CONFIG.SENTRY);
var history = createBrowserHistory();
history.listen(function (location, action) {
  window.gtag('config', CONFIG.GOOGLE_ANALYTICS, {
    page_path: location.pathname
  });
});

var AppRouter = function AppRouter() {
  var params = parse(history.location.search.substring(1));
  var source = params.source,
      connection_id = params.connection_id,
      cloudbeds_access_token = params.cloudbeds_access_token,
      auth_token = params.auth_token;

  var _useContext = useContext(AppContext),
      dispatch = _useContext.dispatch;

  var integration = source === 'bookingsuite' || source === 'cloudbeds'; // const validSource = _.includes(['internal', 'whistle-marketing', 'brandie', 'jro', 'hova', 'hitec2019'], `${source}`.toLowerCase())

  var _useState = useState(!integration),
      _useState2 = _slicedToArray(_useState, 2),
      render = _useState2[0],
      setRender = _useState2[1]; // if (!integration && !validSource && !auth_token) {
  //   window.location.href = 'https://trywhistle.com/schedule/index.html'
  // }


  if (auth_token) {
    Storage.set('x-whistle-auth-token', auth_token);
  }

  LiveChatService();
  useEffect(function () {
    if (source === 'bookingsuite' && connection_id) {
      // fetch bookingsuite hotelinfo
      BookingSuiteService.info(connection_id).then(function (res) {
        var data = res.data.results;
        dispatch({
          type: 'SET_CONTEXT',
          payload: {
            phone: data.account.phone,
            email: data.account.email,
            companyName: data.name,
            country: data.cc1,
            city: data.city,
            state: data.state,
            zip_code: data.zip,
            address_line: data.address,
            roomCount: data.total_rooms
          }
        });
        GA.set(data);
        setRender(true);
      }).catch(function (e) {
        setRender(true);
      });
    } else if (source === 'cloudbeds' && cloudbeds_access_token) {
      CloudbedsService.hotelDetails(cloudbeds_access_token).then(function (res) {
        var data = res.data.results[0]; // console.log(data)

        dispatch({
          type: 'SET_CONTEXT',
          payload: {
            phone: data.propertyPhone,
            email: data.propertyEmail,
            companyName: data.propertyName,
            country: data.propertyAddress.propertyCountry,
            city: data.propertyAddress.propertyCity,
            state: data.propertyAddress.propertyState,
            zip_code: data.propertyAddress.propertyZip,
            address_line: "".concat(data.propertyAddress.propertyAddress1, " ").concat(data.propertyAddress.propertyAddress2),
            roomCount: null
          }
        });
        GA.set(data);
        setRender(true);
      }).catch(function (e) {
        setRender(true);
      });
    } else {
      setRender(true);
    }
  }, _.values(params));

  if (!render) {
    return React.createElement(SplashScreen, null);
  }

  return React.createElement(Router, {
    history: history
  }, React.createElement(Switch, null, React.createElement(Route, {
    path: "/login",
    exact: true,
    component: LoginContainer
  }), React.createElement(Route, {
    path: "/companies",
    exact: true,
    component: CompaniesContainer
  }), React.createElement(Route, {
    path: "/register",
    exact: true,
    component: CreateUserContainer
  }), React.createElement(Route, {
    path: "/verify",
    component: VerifyUserContainer
  }), React.createElement(Route, {
    path: "/company",
    component: CreateCompanyContainer
  }), React.createElement(Route, {
    path: "/plans",
    component: PlansContainer
  }), React.createElement(Route, {
    path: "/extras",
    component: PlansExtraContainer
  }), React.createElement(Route, {
    path: "/billing",
    component: BillingContainer
  }), React.createElement(Route, {
    path: "/sms",
    component: CreateSMSContainer
  }), React.createElement(Route, {
    path: "/invite",
    component: InviteContainer
  }), React.createElement(Route, {
    path: "/",
    component: LoginContainer
  }), React.createElement(Redirect, {
    to: "/"
  })));
};

ReactDOM.render(React.createElement(AppContextProvider, null, React.createElement(StripeProvider, {
  apiKey: CONFIG.STRIP_PUBLIC_KEY
}, React.createElement(AppRouter, null))), document.getElementById('root'));
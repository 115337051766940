import CONFIG from '@whistle/config';

var init = function init() {
  var script = document.createElement("script");
  script.innerHTML = "(function(){window.WhistleLiveChat={ company:'".concat(CONFIG.LIVE_CHAT_BUCKET, "', source: '").concat(CONFIG.LIVE_CHAT_PLUGINS_SOURCE, "' };var e=document.createElement(\"script\");e.async=!0,e.type=\"text/javascript\",e.src=window.WhistleLiveChat.source + \"/live-chat/initialize.js\";var t=document.getElementsByTagName(\"script\")[0];t.parentNode.insertBefore(e,t)}())");
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

export default init;
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Link } from "react-router-dom";

var _ref = process.env.NODE_ENV === "production" ? {
  name: "11eergg",
  styles: "font-family:'Poppins',sans-serif;position:relative;display:flex;color:#1D3657;text-decoration:underline;cursor:pointer;"
} : {
  name: "11eergg",
  styles: "font-family:'Poppins',sans-serif;position:relative;display:flex;color:#1D3657;text-decoration:underline;cursor:pointer;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvTGluay5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFTWSIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9jb21wb25lbnRzL0xpbmsuanMiLCJzb3VyY2VzQ29udGVudCI6WyIvKiogQGpzeCBqc3ggKi9cbmltcG9ydCB7IGpzeCwgY3NzIH0gZnJvbSAnQGVtb3Rpb24vY29yZSdcblxuaW1wb3J0IHsgTGluayB9IGZyb20gXCJyZWFjdC1yb3V0ZXItZG9tXCJcblxuY29uc3QgQ3VzdG9tTGluayA9IHByb3BzID0+IChcbiAgPExpbmtcbiAgICBzdHlsZT17cHJvcHMuc3R5bGV9XG4gICAgdG89e3Byb3BzLnRvfVxuICAgIGNzcz17Y3NzYFxuICAgIGZvbnQtZmFtaWx5OiAnUG9wcGlucycsIHNhbnMtc2VyaWY7XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgY29sb3I6ICMxRDM2NTc7XG4gICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XG4gICAgY3Vyc29yOiBwb2ludGVyO1xuICBgfVxuICA+XG4gICAge3Byb3BzLmNoaWxkcmVufVxuICA8L0xpbms+XG4pXG5cbmV4cG9ydCBkZWZhdWx0IEN1c3RvbUxpbmsiXX0= */"
};

var CustomLink = function CustomLink(props) {
  return jsx(Link, {
    style: props.style,
    to: props.to,
    css: _ref
  }, props.children);
};

export default CustomLink;
import _styled from "@emotion/styled-base";
import React from 'react';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import ReactCodeInput from 'react-code-input';

var Wrapper = _styled("div", {
  target: "e1hphgpg0"
})(process.env.NODE_ENV === "production" ? {
  name: "ozw706",
  styles: "display:flex;align-items:center;justify-content:center;margin-bottom:2rem;"
} : {
  name: "ozw706",
  styles: "display:flex;align-items:center;justify-content:center;margin-bottom:2rem;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL3BhZ2VzL3ZlcmlmeS11c2VyL2NvbXBvbmVudHMvVmVyaWZpY2F0aW9uQ29kZS5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFNMEIiLCJmaWxlIjoiL2hvbWUvY2lyY2xlY2kvcHJvamVjdC9zcmMvcGFnZXMvdmVyaWZ5LXVzZXIvY29tcG9uZW50cy9WZXJpZmljYXRpb25Db2RlLmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG4vKiogQGpzeCBqc3ggKi9cbmltcG9ydCB7IGpzeCwgY3NzIH0gZnJvbSAnQGVtb3Rpb24vY29yZSdcbmltcG9ydCBSZWFjdENvZGVJbnB1dCBmcm9tICdyZWFjdC1jb2RlLWlucHV0J1xuXG5jb25zdCBXcmFwcGVyID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIG1hcmdpbi1ib3R0b206IDJyZW07XG5gXG5cbmNvbnN0IFZlcmlmaWNhdGlvbkNvZGUgPSAocHJvcHMpID0+IHtcbiAgcmV0dXJuIChcbiAgICA8V3JhcHBlcj5cbiAgICAgIDxSZWFjdENvZGVJbnB1dFxuICAgICAgICB0eXBlPSd0ZXh0J1xuICAgICAgICBmaWVsZHM9ezV9XG4gICAgICAgIGlucHV0U3R5bGU9e3tcbiAgICAgICAgICBtYXJnaW46ICcuM3JlbScsXG4gICAgICAgICAgZm9udFNpemU6ICcxLjVyZW0nLFxuICAgICAgICAgIHRleHRBbGlnbjogJ2NlbnRlcicsXG4gICAgICAgICAgd2lkdGg6ICcxLjVyZW0nLFxuICAgICAgICAgIHBhZGRpbmc6ICcwLjVyZW0nLFxuICAgICAgICAgIGJvcmRlclJhZGl1czogJzZweCcsXG4gICAgICAgICAgYm9yZGVyOiAnMnB4IHNvbGlkICMxRDM2NTcnLFxuICAgICAgICAgIG91dGxpbmU6ICcwJyxcbiAgICAgICAgfX1cbiAgICAgICAgdmFsdWU9e3Byb3BzLnZhbHVlfVxuICAgICAgICBvbkNoYW5nZT17cHJvcHMub25DaGFuZ2V9XG4gICAgICAvPlxuICAgIDwvV3JhcHBlcj5cbiAgKTtcbn1cbiBcbmV4cG9ydCBkZWZhdWx0IFZlcmlmaWNhdGlvbkNvZGU7Il19 */"
});

var VerificationCode = function VerificationCode(props) {
  return jsx(Wrapper, null, jsx(ReactCodeInput, {
    type: "text",
    fields: 5,
    inputStyle: {
      margin: '.3rem',
      fontSize: '1.5rem',
      textAlign: 'center',
      width: '1.5rem',
      padding: '0.5rem',
      borderRadius: '6px',
      border: '2px solid #1D3657',
      outline: '0'
    },
    value: props.value,
    onChange: props.onChange
  }));
};

export default VerificationCode;
export default {
  DASHBOARD_HOST: 'whistle.cloudbeds-dev.com',
  GOOGLE_ANALYTICS: 'TODO',
  STRIP_PUBLIC_KEY: 'pk_test_kVubpYk67Hh0wbgfVtaoNnlc',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyBHIpPMOQaNSkV967xmx5McaI3nWiso-Hg',
    authDomain: 'gx-whistle-dev.firebaseapp.com',
    databaseURL: 'https://gx-whistle-dev-default-rtdb.firebaseio.com',
    projectId: 'gx-whistle-dev',
    storageBucket: 'gx-whistle-dev.appspot.com',
    messagingSenderId: '145735243163'
  },
  LIVE_CHAT_BUCKET: 'staging',
  LIVE_CHAT_PLUGINS_SOURCE: 'https://plugins.whistle.cloudbeds-dev.com',
  SENTRY: {},
  USERS_ENDPOINT: 'https://twic18lgs9.execute-api.us-west-2.amazonaws.com/dev/users',
  COMPANIES_ENDPOINT: 'https://kz7g00u82m.execute-api.us-west-2.amazonaws.com/dev/companies',
  BOOKINGSUITE_ENDPOINT: '',
  PHONE_NUMBERS_ENDPOINT: 'https://3ku5hnpgga.execute-api.us-west-2.amazonaws.com/dev/phone-number',
  CLOUDBEDS_ENDPOINT: 'https://7gahfxbaxg.execute-api.us-west-2.amazonaws.com/dev/cloudbeds',
  BILLING_ENDPOINT: 'https://03w18s7z16.execute-api.us-west-2.amazonaws.com/dev/billing',
  AUTH_ENDPOINT: 'https://voc9f14do5.execute-api.us-west-2.amazonaws.com/dev/auth',
  CHOICE_ENDPOINT: '',
  PRIVACY_POLICY_URL: 'https://www.cloudbeds.com/privacy-policy/',
  TOS_URL: 'https://www.cloudbeds.com/terms/whistle/',
  SUPPORT_MAIL: 'support@cloudbeds.com'
};
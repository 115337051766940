import React, { useEffect } from 'react';
import { parse } from 'querystring';
import _ from 'lodash';
import CONFIG from '@whistle/config';
import SplashScreen from '../../splash-screen/SplashScreen';

var LoginContainer = function LoginContainer(props) {
  var params = parse(props.location.search.substring(1));
  useEffect(function () {
    window.location.href = "https://".concat(CONFIG.DASHBOARD_HOST, "/").concat(params.property_id || '');
  }, []);
  return React.createElement(SplashScreen, null);
};

export default LoginContainer;
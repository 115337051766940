import _styled from "@emotion/styled-base";
import React from 'react';
import { parse, stringify } from 'querystring';
import { withRouter } from 'react-router-dom';
import Button from './Button';
import TitleText from './TitleText';

var Center = _styled("div", {
  target: "e1ufucuh0"
})(process.env.NODE_ENV === "production" ? {
  name: "1ljxb89",
  styles: "display:flex;align-items:center;flex-direction:column;justify-content:center;height:50vh;"
} : {
  name: "1ljxb89",
  styles: "display:flex;align-items:center;flex-direction:column;justify-content:center;height:50vh;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvU3VjY2Vzcy5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFReUIiLCJmaWxlIjoiL2hvbWUvY2lyY2xlY2kvcHJvamVjdC9zcmMvY29tcG9uZW50cy9TdWNjZXNzLmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuaW1wb3J0IHsgcGFyc2UsIHN0cmluZ2lmeSB9IGZyb20gJ3F1ZXJ5c3RyaW5nJ1xuaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG5pbXBvcnQgeyB3aXRoUm91dGVyIH0gZnJvbSAncmVhY3Qtcm91dGVyLWRvbSc7XG5cbmltcG9ydCBCdXR0b24gZnJvbSAnLi9CdXR0b24nXG5pbXBvcnQgVGl0bGVUZXh0IGZyb20gJy4vVGl0bGVUZXh0J1xuXG5jb25zdCBDZW50ZXIgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgaGVpZ2h0OiA1MHZoO1xuYFxuXG5jb25zdCBCdXR0b25Db250YWluZXIgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgbWFyZ2luLXRvcDogMnJlbTtcbmBcblxuY29uc3QgVGV4dCA9IHN0eWxlZC5wYFxuICBmb250LWZhbWlseTogJ1BvcHBpbnMnLCBzYW5zLXNlcmlmO1xuICBkaXNwbGF5OiBmbGV4O1xuYFxuXG5jb25zdCBTdWNjZXNzID0gKHByb3BzKSA9PiB7XG4gIGNvbnN0IHBhcmFtcyA9IHBhcnNlKHByb3BzLmxvY2F0aW9uLnNlYXJjaC5zdWJzdHJpbmcoMSkpXG5cbiAgcGFyYW1zLmVtYWlsID0gcHJvcHMuZW1haWxcblxuICBjb25zdCBvbkNsaWNrID0gKCkgPT4ge1xuICAgIHByb3BzLmhpc3RvcnkucHVzaCh7XG4gICAgICBwYXRobmFtZTogcHJvcHMucGF0aG5hbWUsXG4gICAgICBzZWFyY2g6IGA/JHtzdHJpbmdpZnkocGFyYW1zKX1gXG4gICAgfSlcbiAgfSBcblxuICByZXR1cm4gKFxuICAgIDxDZW50ZXI+XG4gICAgICA8VGl0bGVUZXh0IGNlbnRlcj57cHJvcHMudGl0bGV9PC9UaXRsZVRleHQ+XG4gICAgICA8SWYgY29uZGl0aW9uPXtwcm9wcy50ZXh0fT5cbiAgICAgICAgPFRleHQ+e3Byb3BzLnRleHR9PC9UZXh0PlxuICAgICAgPC9JZj5cbiAgICAgIDxCdXR0b25Db250YWluZXI+XG4gICAgICAgIDxCdXR0b25cbiAgICAgICAgICBpZD1cImxvZ2luLWxpbmtcIlxuICAgICAgICAgIHRleHQ9e3Byb3BzLmJ1dHRvblRleHR9XG4gICAgICAgICAgb25DbGljaz17b25DbGlja31cbiAgICAgICAgLz5cbiAgICAgIDwvQnV0dG9uQ29udGFpbmVyPlxuICAgIDwvQ2VudGVyPlxuICApXG59XG5cbmV4cG9ydCBkZWZhdWx0IHdpdGhSb3V0ZXIoU3VjY2VzcykiXX0= */"
});

var ButtonContainer = _styled("div", {
  target: "e1ufucuh1"
})(process.env.NODE_ENV === "production" ? {
  name: "1p4marh",
  styles: "display:flex;justify-content:center;margin-top:2rem;"
} : {
  name: "1p4marh",
  styles: "display:flex;justify-content:center;margin-top:2rem;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvU3VjY2Vzcy5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFnQmtDIiwiZmlsZSI6Ii9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvU3VjY2Vzcy5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcbmltcG9ydCB7IHBhcnNlLCBzdHJpbmdpZnkgfSBmcm9tICdxdWVyeXN0cmluZydcbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuaW1wb3J0IHsgd2l0aFJvdXRlciB9IGZyb20gJ3JlYWN0LXJvdXRlci1kb20nO1xuXG5pbXBvcnQgQnV0dG9uIGZyb20gJy4vQnV0dG9uJ1xuaW1wb3J0IFRpdGxlVGV4dCBmcm9tICcuL1RpdGxlVGV4dCdcblxuY29uc3QgQ2VudGVyID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGhlaWdodDogNTB2aDtcbmBcblxuY29uc3QgQnV0dG9uQ29udGFpbmVyID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIG1hcmdpbi10b3A6IDJyZW07XG5gXG5cbmNvbnN0IFRleHQgPSBzdHlsZWQucGBcbiAgZm9udC1mYW1pbHk6ICdQb3BwaW5zJywgc2Fucy1zZXJpZjtcbiAgZGlzcGxheTogZmxleDtcbmBcblxuY29uc3QgU3VjY2VzcyA9IChwcm9wcykgPT4ge1xuICBjb25zdCBwYXJhbXMgPSBwYXJzZShwcm9wcy5sb2NhdGlvbi5zZWFyY2guc3Vic3RyaW5nKDEpKVxuXG4gIHBhcmFtcy5lbWFpbCA9IHByb3BzLmVtYWlsXG5cbiAgY29uc3Qgb25DbGljayA9ICgpID0+IHtcbiAgICBwcm9wcy5oaXN0b3J5LnB1c2goe1xuICAgICAgcGF0aG5hbWU6IHByb3BzLnBhdGhuYW1lLFxuICAgICAgc2VhcmNoOiBgPyR7c3RyaW5naWZ5KHBhcmFtcyl9YFxuICAgIH0pXG4gIH0gXG5cbiAgcmV0dXJuIChcbiAgICA8Q2VudGVyPlxuICAgICAgPFRpdGxlVGV4dCBjZW50ZXI+e3Byb3BzLnRpdGxlfTwvVGl0bGVUZXh0PlxuICAgICAgPElmIGNvbmRpdGlvbj17cHJvcHMudGV4dH0+XG4gICAgICAgIDxUZXh0Pntwcm9wcy50ZXh0fTwvVGV4dD5cbiAgICAgIDwvSWY+XG4gICAgICA8QnV0dG9uQ29udGFpbmVyPlxuICAgICAgICA8QnV0dG9uXG4gICAgICAgICAgaWQ9XCJsb2dpbi1saW5rXCJcbiAgICAgICAgICB0ZXh0PXtwcm9wcy5idXR0b25UZXh0fVxuICAgICAgICAgIG9uQ2xpY2s9e29uQ2xpY2t9XG4gICAgICAgIC8+XG4gICAgICA8L0J1dHRvbkNvbnRhaW5lcj5cbiAgICA8L0NlbnRlcj5cbiAgKVxufVxuXG5leHBvcnQgZGVmYXVsdCB3aXRoUm91dGVyKFN1Y2Nlc3MpIl19 */"
});

var Text = _styled("p", {
  target: "e1ufucuh2"
})(process.env.NODE_ENV === "production" ? {
  name: "1lp7t57",
  styles: "font-family:'Poppins',sans-serif;display:flex;"
} : {
  name: "1lp7t57",
  styles: "font-family:'Poppins',sans-serif;display:flex;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvU3VjY2Vzcy5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFzQnFCIiwiZmlsZSI6Ii9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvU3VjY2Vzcy5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcbmltcG9ydCB7IHBhcnNlLCBzdHJpbmdpZnkgfSBmcm9tICdxdWVyeXN0cmluZydcbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuaW1wb3J0IHsgd2l0aFJvdXRlciB9IGZyb20gJ3JlYWN0LXJvdXRlci1kb20nO1xuXG5pbXBvcnQgQnV0dG9uIGZyb20gJy4vQnV0dG9uJ1xuaW1wb3J0IFRpdGxlVGV4dCBmcm9tICcuL1RpdGxlVGV4dCdcblxuY29uc3QgQ2VudGVyID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIGhlaWdodDogNTB2aDtcbmBcblxuY29uc3QgQnV0dG9uQ29udGFpbmVyID0gc3R5bGVkLmRpdmBcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG4gIG1hcmdpbi10b3A6IDJyZW07XG5gXG5cbmNvbnN0IFRleHQgPSBzdHlsZWQucGBcbiAgZm9udC1mYW1pbHk6ICdQb3BwaW5zJywgc2Fucy1zZXJpZjtcbiAgZGlzcGxheTogZmxleDtcbmBcblxuY29uc3QgU3VjY2VzcyA9IChwcm9wcykgPT4ge1xuICBjb25zdCBwYXJhbXMgPSBwYXJzZShwcm9wcy5sb2NhdGlvbi5zZWFyY2guc3Vic3RyaW5nKDEpKVxuXG4gIHBhcmFtcy5lbWFpbCA9IHByb3BzLmVtYWlsXG5cbiAgY29uc3Qgb25DbGljayA9ICgpID0+IHtcbiAgICBwcm9wcy5oaXN0b3J5LnB1c2goe1xuICAgICAgcGF0aG5hbWU6IHByb3BzLnBhdGhuYW1lLFxuICAgICAgc2VhcmNoOiBgPyR7c3RyaW5naWZ5KHBhcmFtcyl9YFxuICAgIH0pXG4gIH0gXG5cbiAgcmV0dXJuIChcbiAgICA8Q2VudGVyPlxuICAgICAgPFRpdGxlVGV4dCBjZW50ZXI+e3Byb3BzLnRpdGxlfTwvVGl0bGVUZXh0PlxuICAgICAgPElmIGNvbmRpdGlvbj17cHJvcHMudGV4dH0+XG4gICAgICAgIDxUZXh0Pntwcm9wcy50ZXh0fTwvVGV4dD5cbiAgICAgIDwvSWY+XG4gICAgICA8QnV0dG9uQ29udGFpbmVyPlxuICAgICAgICA8QnV0dG9uXG4gICAgICAgICAgaWQ9XCJsb2dpbi1saW5rXCJcbiAgICAgICAgICB0ZXh0PXtwcm9wcy5idXR0b25UZXh0fVxuICAgICAgICAgIG9uQ2xpY2s9e29uQ2xpY2t9XG4gICAgICAgIC8+XG4gICAgICA8L0J1dHRvbkNvbnRhaW5lcj5cbiAgICA8L0NlbnRlcj5cbiAgKVxufVxuXG5leHBvcnQgZGVmYXVsdCB3aXRoUm91dGVyKFN1Y2Nlc3MpIl19 */"
});

var Success = function Success(props) {
  var params = parse(props.location.search.substring(1));
  params.email = props.email;

  var onClick = function onClick() {
    props.history.push({
      pathname: props.pathname,
      search: "?".concat(stringify(params))
    });
  };

  return React.createElement(Center, null, React.createElement(TitleText, {
    center: true
  }, props.title), props.text ? React.createElement(Text, null, props.text) : null, React.createElement(ButtonContainer, null, React.createElement(Button, {
    id: "login-link",
    text: props.buttonText,
    onClick: onClick
  })));
};

export default withRouter(Success);
import _styled from "@emotion/styled-base";
import React from 'react';
import { withRouter } from 'react-router-dom';

var Icon = _styled("i", {
  target: "e1jpjw600"
})(function (props) {
  return "\n  font-size: 2rem;\n";
}, process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvQmFja0J1dHRvbi5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJYSIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9jb21wb25lbnRzL0JhY2tCdXR0b24uanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcbmltcG9ydCB7IHdpdGhSb3V0ZXIgfSBmcm9tICdyZWFjdC1yb3V0ZXItZG9tJztcblxuY29uc3QgSWNvbiA9IHN0eWxlZC5pKHByb3BzID0+IGBcbiAgZm9udC1zaXplOiAycmVtO1xuYClcblxuY29uc3QgQnV0dG9uID0gc3R5bGVkLmJ1dHRvbihwcm9wcyA9PiBgXG4gIHBhZGRpbmc6IDAuNXJlbTtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB0b3A6IDA7XG4gIG91dGxpbmU6IDA7XG4gIG1hcmdpbjogMXJlbSAuNXJlbTtcbiAgbGVmdDogMDtcbiAgYm9yZGVyLXJhZGl1czogNTAlO1xuICBib3JkZXI6IDFweCBzb2xpZCBsaWdodGdyYXk7XG4gIGJhY2tncm91bmQ6IHRyYW5zcGFyZW50O1xuICBpIHtcbiAgICBjb2xvcjogbGlnaHRncmF5O1xuICAgIG9wYWNpdHk6IDE7XG4gIH1cbiAgJjpob3ZlciB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogbGlnaHRncmF5O1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICBvcGFjaXR5OiAwLjU7XG4gICAgdHJhbnNpdGlvbjogLjJzIGVhc2UtaW47XG4gICAgaSB7XG4gICAgICBjb2xvcjogZ3JheTtcbiAgICB9XG4gIH1cbmApXG5cbmNvbnN0IEJhY2tCdXR0b24gPSAocHJvcHMpID0+IHtcbiAgcmV0dXJuIChcbiAgICA8QnV0dG9uIG9uQ2xpY2s9eygpID0+IHByb3BzLmhpc3RvcnkuZ29CYWNrKCl9PlxuICAgICAgPEljb24gY2xhc3NOYW1lPXtgbWF0ZXJpYWwtaWNvbnNgfT5rZXlib2FyZF9hcnJvd19sZWZ0PC9JY29uPlxuICAgIDwvQnV0dG9uPlxuICApO1xufVxuIFxuZXhwb3J0IGRlZmF1bHQgd2l0aFJvdXRlcihCYWNrQnV0dG9uKTsiXX0= */");

var Button = _styled("button", {
  target: "e1jpjw601"
})(function (props) {
  return "\n  padding: 0.5rem;\n  position: absolute;\n  top: 0;\n  outline: 0;\n  margin: 1rem .5rem;\n  left: 0;\n  border-radius: 50%;\n  border: 1px solid lightgray;\n  background: transparent;\n  i {\n    color: lightgray;\n    opacity: 1;\n  }\n  &:hover {\n    background-color: lightgray;\n    cursor: pointer;\n    opacity: 0.5;\n    transition: .2s ease-in;\n    i {\n      color: gray;\n    }\n  }\n";
}, process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvQmFja0J1dHRvbi5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFRZSIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9jb21wb25lbnRzL0JhY2tCdXR0b24uanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcbmltcG9ydCB7IHdpdGhSb3V0ZXIgfSBmcm9tICdyZWFjdC1yb3V0ZXItZG9tJztcblxuY29uc3QgSWNvbiA9IHN0eWxlZC5pKHByb3BzID0+IGBcbiAgZm9udC1zaXplOiAycmVtO1xuYClcblxuY29uc3QgQnV0dG9uID0gc3R5bGVkLmJ1dHRvbihwcm9wcyA9PiBgXG4gIHBhZGRpbmc6IDAuNXJlbTtcbiAgcG9zaXRpb246IGFic29sdXRlO1xuICB0b3A6IDA7XG4gIG91dGxpbmU6IDA7XG4gIG1hcmdpbjogMXJlbSAuNXJlbTtcbiAgbGVmdDogMDtcbiAgYm9yZGVyLXJhZGl1czogNTAlO1xuICBib3JkZXI6IDFweCBzb2xpZCBsaWdodGdyYXk7XG4gIGJhY2tncm91bmQ6IHRyYW5zcGFyZW50O1xuICBpIHtcbiAgICBjb2xvcjogbGlnaHRncmF5O1xuICAgIG9wYWNpdHk6IDE7XG4gIH1cbiAgJjpob3ZlciB7XG4gICAgYmFja2dyb3VuZC1jb2xvcjogbGlnaHRncmF5O1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICBvcGFjaXR5OiAwLjU7XG4gICAgdHJhbnNpdGlvbjogLjJzIGVhc2UtaW47XG4gICAgaSB7XG4gICAgICBjb2xvcjogZ3JheTtcbiAgICB9XG4gIH1cbmApXG5cbmNvbnN0IEJhY2tCdXR0b24gPSAocHJvcHMpID0+IHtcbiAgcmV0dXJuIChcbiAgICA8QnV0dG9uIG9uQ2xpY2s9eygpID0+IHByb3BzLmhpc3RvcnkuZ29CYWNrKCl9PlxuICAgICAgPEljb24gY2xhc3NOYW1lPXtgbWF0ZXJpYWwtaWNvbnNgfT5rZXlib2FyZF9hcnJvd19sZWZ0PC9JY29uPlxuICAgIDwvQnV0dG9uPlxuICApO1xufVxuIFxuZXhwb3J0IGRlZmF1bHQgd2l0aFJvdXRlcihCYWNrQnV0dG9uKTsiXX0= */");

var BackButton = function BackButton(props) {
  return React.createElement(Button, {
    onClick: function onClick() {
      return props.history.goBack();
    }
  }, React.createElement(Icon, {
    className: "material-icons"
  }, "keyboard_arrow_left"));
};

export default withRouter(BackButton);
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useState } from 'react';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Form from '../../../components/Form';
import ErrorText from '../../../components/ErrorText';

var AreaCodeForm = function AreaCodeForm(props) {
  var _useState = useState(''),
      _useState2 = _slicedToArray(_useState, 2),
      areaCode = _useState2[0],
      setAreaCode = _useState2[1];

  var _onSubmit = function onSubmit(e) {
    e.preventDefault();
    props.onSubmit(areaCode);
  };

  return React.createElement(Form, {
    onSubmit: function onSubmit(e) {
      return _onSubmit(e);
    }
  }, React.createElement(Input, {
    type: "tel",
    label: "Enter a new Area Code",
    id: "area-code",
    placeholder: props.areaCode,
    maxLength: 3,
    textAlign: 'center',
    styles: 'width: 50%; margin: 0 auto;',
    onChange: function onChange(e) {
      return setAreaCode(e.target.value);
    },
    center: true
  }), React.createElement(ErrorText, null, props.errorText), React.createElement(Button, {
    id: "email-submit",
    text: "Next >>",
    loading: props.loading,
    disabled: areaCode.length !== 3,
    center: true
  }));
};

export default AreaCodeForm;
import _styled from "@emotion/styled-base";

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translate3d(0,-5%,0)\n  }\n\n  to {\n    opacity: 1;\n    transform: translateZ(0)\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import React from 'react';
import { keyframes } from '@emotion/core';
var fadeIn = keyframes(_templateObject());

var Vector = _styled("img", {
  target: "e1yo71l60"
})(function (props) {
  return _objectSpread({}, props.style, {
    width: '100%',
    opacity: 0,
    animation: "".concat(fadeIn, " .5s ease forwards"),
    animationDelay: '100ms'
  });
}, process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvU1ZHVmVjdG9yLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQWdCZSIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9jb21wb25lbnRzL1NWR1ZlY3Rvci5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuaW1wb3J0IHsga2V5ZnJhbWVzIH0gZnJvbSAnQGVtb3Rpb24vY29yZSdcblxuY29uc3QgZmFkZUluID0ga2V5ZnJhbWVzYFxuICAwJSB7XG4gICAgb3BhY2l0eTogMDtcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZTNkKDAsLTUlLDApXG4gIH1cblxuICB0byB7XG4gICAgb3BhY2l0eTogMTtcbiAgICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVooMClcbiAgfVxuYFxuXG5jb25zdCBWZWN0b3IgPSBzdHlsZWQuaW1nKHByb3BzID0+ICh7XG4gIC4uLnByb3BzLnN0eWxlLFxuICB3aWR0aDogJzEwMCUnLFxuICBvcGFjaXR5OiAwLFxuICBhbmltYXRpb246IGAke2ZhZGVJbn0gLjVzIGVhc2UgZm9yd2FyZHNgLFxuICBhbmltYXRpb25EZWxheTogJzEwMG1zJ1xufSkpXG5cbmNvbnN0IFNWR1ZlY3RvciA9IChwcm9wcykgPT4ge1xuICByZXR1cm4gKFxuICAgIDxWZWN0b3Igc3JjPXtwcm9wcy5zcmN9IC8+XG4gIClcbn1cblxuZXhwb3J0IGRlZmF1bHQgU1ZHVmVjdG9yIl19 */");

var SVGVector = function SVGVector(props) {
  return React.createElement(Vector, {
    src: props.src
  });
};

export default SVGVector;
import _styled from "@emotion/styled-base";

var Form = _styled("form", {
  target: "e1n9xnrh0"
})(process.env.NODE_ENV === "production" ? {
  name: "3jp1ai",
  styles: "width:100%;margin-top:1.5rem;"
} : {
  name: "3jp1ai",
  styles: "width:100%;margin-top:1.5rem;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvRm9ybS5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFd0IiLCJmaWxlIjoiL2hvbWUvY2lyY2xlY2kvcHJvamVjdC9zcmMvY29tcG9uZW50cy9Gb3JtLmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG5cbmNvbnN0IEZvcm0gPSBzdHlsZWQuZm9ybWBcbiAgd2lkdGg6IDEwMCU7XG4gIG1hcmdpbi10b3A6IDEuNXJlbTtcbmBcblxuZXhwb3J0IGRlZmF1bHQgRm9ybSJdfQ== */"
});

export default Form;
import _styled from "@emotion/styled-base";
import React from 'react';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import ReactCodeInput from 'react-code-input';

var Wrapper = _styled("div", {
  target: "e12xnlbg0"
})(process.env.NODE_ENV === "production" ? {
  name: "ozw706",
  styles: "display:flex;align-items:center;justify-content:center;margin-bottom:2rem;"
} : {
  name: "ozw706",
  styles: "display:flex;align-items:center;justify-content:center;margin-bottom:2rem;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL3BhZ2VzL2Nob2ljZS1jb2RlL2NvbXBvbmVudHMvUHJvcGVydHlDb2RlLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU0wQiIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9wYWdlcy9jaG9pY2UtY29kZS9jb21wb25lbnRzL1Byb3BlcnR5Q29kZS5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuLyoqIEBqc3gganN4ICovXG5pbXBvcnQgeyBqc3gsIGNzcyB9IGZyb20gJ0BlbW90aW9uL2NvcmUnXG5pbXBvcnQgUmVhY3RDb2RlSW5wdXQgZnJvbSAncmVhY3QtY29kZS1pbnB1dCdcblxuY29uc3QgV3JhcHBlciA9IHN0eWxlZC5kaXZgXG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBtYXJnaW4tYm90dG9tOiAycmVtO1xuYFxuXG5jb25zdCBQcm9wZXJ0eUNvZGUgPSAocHJvcHMpID0+IHtcbiAgcmV0dXJuIChcbiAgICA8V3JhcHBlcj5cbiAgICAgIDxSZWFjdENvZGVJbnB1dFxuICAgICAgICB0eXBlPSd0ZXh0J1xuICAgICAgICBmaWVsZHM9ezV9XG4gICAgICAgIGlucHV0U3R5bGU9e3tcbiAgICAgICAgICBtYXJnaW46ICcuM3JlbScsXG4gICAgICAgICAgZm9udFNpemU6ICcxLjVyZW0nLFxuICAgICAgICAgIHRleHRBbGlnbjogJ2NlbnRlcicsXG4gICAgICAgICAgd2lkdGg6ICcxLjVyZW0nLFxuICAgICAgICAgIHBhZGRpbmc6ICcwLjVyZW0nLFxuICAgICAgICAgIGJvcmRlclJhZGl1czogJzZweCcsXG4gICAgICAgICAgYm9yZGVyOiAnMnB4IHNvbGlkICMxRDM2NTcnLFxuICAgICAgICAgIG91dGxpbmU6ICcwJyxcbiAgICAgICAgfX1cbiAgICAgICAgdmFsdWU9e3Byb3BzLnZhbHVlfVxuICAgICAgICBvbkNoYW5nZT17cHJvcHMub25DaGFuZ2V9XG4gICAgICAvPlxuICAgIDwvV3JhcHBlcj5cbiAgKTtcbn1cbiBcbmV4cG9ydCBkZWZhdWx0IFByb3BlcnR5Q29kZTsiXX0= */"
});

var PropertyCode = function PropertyCode(props) {
  return jsx(Wrapper, null, jsx(ReactCodeInput, {
    type: "text",
    fields: 5,
    inputStyle: {
      margin: '.3rem',
      fontSize: '1.5rem',
      textAlign: 'center',
      width: '1.5rem',
      padding: '0.5rem',
      borderRadius: '6px',
      border: '2px solid #1D3657',
      outline: '0'
    },
    value: props.value,
    onChange: props.onChange
  }));
};

export default PropertyCode;
import _styled from "@emotion/styled-base";

var Container = _styled("div", {
  target: "e1immzvs0"
})(process.env.NODE_ENV === "production" ? {
  name: "mwbfth",
  styles: "min-height:100vh;display:flex;flex:1;"
} : {
  name: "mwbfth",
  styles: "min-height:100vh;display:flex;flex:1;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvQ29udGFpbmVyLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUU0QiIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9jb21wb25lbnRzL0NvbnRhaW5lci5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5jb25zdCBDb250YWluZXIgPSBzdHlsZWQuZGl2YFxuICBtaW4taGVpZ2h0OiAxMDB2aDtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleDogMTtcbmBcblxuZXhwb3J0IGRlZmF1bHQgQ29udGFpbmVyIl19 */"
});

export default Container;
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React, { useState, useContext } from 'react';
import Responsive from 'react-responsive';
import { parse, stringify } from 'querystring';
import styled from '@emotion/styled';
import CONFIG from '@whistle/config';
import Container from '../../../components/Container';
import Column from '../../../components/Column';
import Logo from '../../../components/Logo';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import TitleText from '../../../components/TitleText';
import Form from '../../../components/Form';
import ErrorText from '../../../components/ErrorText';
import SubTitleText from '../../../components/SubTitleText';
import SVGVector from '../../../components/SVGVector';
import BucketField from '../components/BucketField';
import { AppContext } from '../../../AppContext';
import CompanyService from '../../../services/Company';
import Storage from '../../../services/Storage';
import GA from '../../../services/GoogleAnalytics';
import createCompanyImg from '../../../assets/create-company.png';
import PMSOptions from '../PMSOptions';

var Tablet = function Tablet(props) {
  return React.createElement(Responsive, _extends({}, props, {
    minWidth: 769
  }));
};

var salesEmail = "<a href='mailto:sales@trywhistle.com'>sales@trywhistle.com</a>";
var supportEmail = "<a href='mailto:".concat(CONFIG.SUPPORT_MAIL, "'>").concat(CONFIG.SUPPORT_MAIL, "</a>");

var CreateCompanyContainer = function CreateCompanyContainer(props) {
  var _useContext = useContext(AppContext),
      state = _useContext.state;

  var params = parse(props.location.search.substring(1));

  var _useState = useState(''),
      _useState2 = _slicedToArray(_useState, 2),
      email = _useState2[0],
      setEmail = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      launchDate = _useState4[0],
      setLaunchDate = _useState4[1];

  var _useState5 = useState(state.companyName || ''),
      _useState6 = _slicedToArray(_useState5, 2),
      companyName = _useState6[0],
      setCompanyName = _useState6[1];

  var _useState7 = useState(state.roomCount || ''),
      _useState8 = _slicedToArray(_useState7, 2),
      roomCount = _useState8[0],
      setRoomCount = _useState8[1];

  var _useState9 = useState('CloudBeds'),
      _useState10 = _slicedToArray(_useState9, 2),
      PMS = _useState10[0],
      setPMS = _useState10[1];

  var _useState11 = useState(state.otherPMS || ''),
      _useState12 = _slicedToArray(_useState11, 2),
      otherPMS = _useState12[0],
      setOtherPMS = _useState12[1];

  var _useState13 = useState(''),
      _useState14 = _slicedToArray(_useState13, 2),
      slug = _useState14[0],
      setSlug = _useState14[1];

  var _useState15 = useState(false),
      _useState16 = _slicedToArray(_useState15, 2),
      slugInvalid = _useState16[0],
      setSlugInvalid = _useState16[1];

  var _useState17 = useState(''),
      _useState18 = _slicedToArray(_useState17, 2),
      errorText = _useState18[0],
      setErrorText = _useState18[1];

  var _useState19 = useState(false),
      _useState20 = _slicedToArray(_useState19, 2),
      loading = _useState20[0],
      setLoading = _useState20[1];

  if (!Storage.get('x-whistle-sso-token')) {
    props.history.replace({
      pathname: '/register'
    });
    return React.createElement("div", null);
  }

  var promptTerms = function promptTerms(e) {
    e.preventDefault();
    CompanyService.validate(slug).then(function (res) {
      setLoading(false);

      if (res.data.results.company) {
        // already exists
        setSlugInvalid(true);
      } else {
        setSlugInvalid(false);
        onSubmit();
      }
    });
  };

  var onSubmit = function onSubmit() {
    setLoading(true);
    GA.event(params.source, {
      category: 'CreateCompany',
      label: 'Create',
      action: 'Company Creation Attempted'
    });
    params.company = slug;
    params.name = companyName;
    params.rooms = roomCount;
    params.launch_date = launchDate;
    if (params.source === 'internal' || params.source === 'implementations' || params.source === 'whistle_plus_stripe') params.email = email;
    params.PMS = PMS;

    if (otherPMS) {
      params.other_PMS = otherPMS;
    } // cloudbeds - plans - cc
    // linkedin - plans - cc
    // hitec2019 - plans - cc
    // internal - sms
    // booking - sms


    var data = _objectSpread({}, params, state);

    if (params.source === 'bookingsuite' || params.source === 'internal' || params.source === 'implementations' || params.source === 'whistle_plus_stripe') {
      data.email = email;
      data.launch_date = launchDate;
    }

    if (params.source === 'bookingsuite' || params.source === 'internal') {
      CompanyService.create(slug, companyName, roomCount, data).then(function () {
        setLoading(false);
        GA.event(params.source, {
          category: 'CreateCompany',
          label: 'Create',
          action: "Company Creation Successful: ".concat(params.source)
        });
        props.history.push({
          pathname: "/sms",
          search: "?".concat(stringify(params))
        });
      }).catch(function (err) {
        console.log({
          err: err
        });
        setLoading(false);
        GA.error(err);
        GA.event(params.source, {
          category: 'CreateCompany',
          label: 'Create',
          action: 'Company Creation Failed'
        }); // error creating bucket

        setErrorText("Oops! Something went wrong... please send us an email ".concat(supportEmail));
        Storage.set('x-whistle-sso-token', '');
        Storage.set('x-whistle-auth-token', '');
        props.history.replace({
          pathname: '/register'
        });
      });
    } else {
      GA.event(params.source, {
        category: 'CreateCompany',
        label: 'Create',
        action: "Company Creation Successful: ".concat(params.source)
      });
      props.history.push({
        pathname: "/plans",
        search: "?".concat(stringify(params))
      });
    }
  };

  var updateSlug = function updateSlug(val) {
    val = val.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    setSlug(val); // validate slug

    setLoading(true);
    CompanyService.validate(val).then(function (res) {
      setLoading(false);

      if (res.data.results.company) {
        // already exists
        setSlugInvalid(true);
      } else {
        setSlugInvalid(false);
      }
    });
  };

  var checkDisabled = function checkDisabled() {
    if (!PMS || PMS === "-- Select One --") return true;
    if (PMS === "Other" && !otherPMS) return true;
    return !companyName.length || !roomCount || !slug.length || slugInvalid || loading;
  };

  return React.createElement(Container, null, React.createElement(Tablet, null, React.createElement(Column, {
    bgImage: createCompanyImg
  })), React.createElement(Column, {
    backgroundColor: "white"
  }, React.createElement(Logo, null), React.createElement(TitleText, {
    center: true
  }, "Let\u2019s customize"), React.createElement(SubTitleText, {
    center: true
  }, "Time to build your hotel\u2019s very own platform"), React.createElement(Form, {
    onSubmit: function onSubmit(e) {
      return promptTerms(e);
    }
  }, params.source === 'internal' || params.source === 'implementations' || params.source === 'whistle_plus_stripe' ? [React.createElement(Input, {
    hiddenLabel: true,
    label: 'Company Champion Email',
    id: 'email',
    placeholder: 'Company Champion Email',
    value: email,
    onChange: function onChange(e) {
      return setEmail(e.target.value);
    },
    key: "0"
  }), React.createElement(Input, {
    type: "date",
    label: 'Trial Start Date',
    id: 'trial',
    placeholder: 'Trial Start Date',
    value: launchDate,
    onChange: function onChange(e) {
      return setLaunchDate(e.target.value);
    },
    key: "1"
  })] : null, React.createElement(Input, {
    hiddenLabel: true,
    label: 'Your Hotel Name',
    id: 'hotel',
    placeholder: 'Enter your hotel name',
    value: companyName,
    onChange: function onChange(e) {
      return setCompanyName(e.target.value);
    }
  }), React.createElement(Input, {
    hiddenLabel: true,
    label: 'Room Count',
    id: 'room_count',
    placeholder: 'Number of rooms',
    value: roomCount,
    type: 'number',
    onChange: function onChange(e) {
      return setRoomCount(e.target.value);
    }
  }), React.createElement(BucketField, {
    onChange: function onChange(e) {
      return updateSlug(e.target.value);
    },
    value: slug,
    invalid: slugInvalid
  }), React.createElement(Button, {
    text: "Next >>",
    disabled: checkDisabled(),
    loading: loading,
    center: true
  })), React.createElement(ErrorText, null, errorText)));
};

export default CreateCompanyContainer;
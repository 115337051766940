import _styled from "@emotion/styled-base";
import React from 'react';
import ErrorText from '../../../components/ErrorText';
import TitleText from '../../../components/TitleText';
import Button from '../../../components/Button';

var ButtonContainer = _styled("div", {
  target: "e17bdt3t0"
})(process.env.NODE_ENV === "production" ? {
  name: "1v6cv7a",
  styles: "display:flex;justify-content:flex-start;button{margin-left:1rem;}"
} : {
  name: "1v6cv7a",
  styles: "display:flex;justify-content:flex-start;button{margin-left:1rem;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL3BhZ2VzL2NyZWF0ZS1zbXMvY29tcG9uZW50cy9JbnRlcm5hdGlvbmFsUHJvbXB0LmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU9rQyIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9wYWdlcy9jcmVhdGUtc21zL2NvbXBvbmVudHMvSW50ZXJuYXRpb25hbFByb21wdC5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5pbXBvcnQgRXJyb3JUZXh0IGZyb20gJy4uLy4uLy4uL2NvbXBvbmVudHMvRXJyb3JUZXh0JyBcbmltcG9ydCBUaXRsZVRleHQgZnJvbSAnLi4vLi4vLi4vY29tcG9uZW50cy9UaXRsZVRleHQnXG5pbXBvcnQgQnV0dG9uIGZyb20gJy4uLy4uLy4uL2NvbXBvbmVudHMvQnV0dG9uJ1xuXG5jb25zdCBCdXR0b25Db250YWluZXIgPSBzdHlsZWQuZGl2YFxuICBkaXNwbGF5OiBmbGV4O1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtc3RhcnQ7XG4gIGJ1dHRvbiB7XG4gICAgbWFyZ2luLWxlZnQ6IDFyZW07XG4gIH1cbmBcblxuLy8gcHJlcHBpbmcgaW50ZXJuYXRpb24gY29tcG9uZW50XG5jb25zdCBJbnRlcm5hdGlvbmFsUHJvbXB0ID0gKHByb3BzKSA9PiB7XG4gIHJldHVybiAoXG4gICAgPGRpdj5cbiAgICAgIDxFcnJvclRleHQ+XG4gICAgICAgIHtgU29ycnksIHdlIGRvbid0IHN1cHBvcnQgdGhhdCBjb3VudHJ5IGNvZGUgeWV0ISBXZSByZWNvbW1lbmQgYSBVLlMuICgrMSkgb3IgVUsgKCs0NCkgbnVtYmVyIGZvciB0aGUgYmVzdCBleHBlcmllbmNlIHdpdGggaW50ZXJuYXRpb25hbCBtZXNzYWdpbmdgfVxuICAgICAgPC9FcnJvclRleHQ+XG5cbiAgICAgIDxCdXR0b25Db250YWluZXI+XG4gICAgICAgIDxCdXR0b25cbiAgICAgICAgICB0ZXh0PXtcIlRyeSBVUyAoKzEpIE51bWJlclwifVxuICAgICAgICAgIG9uQ2xpY2s9eygpID0+IHByb3BzLm9uU2VsZWN0QWx0KCdVUycpfVxuICAgICAgICAvPlxuICAgICAgICA8QnV0dG9uXG4gICAgICAgICAgdGV4dD17XCJUcnkgVUsgKCs0NCkgTnVtYmVyXCJ9XG4gICAgICAgICAgb25DbGljaz17KCkgPT4gcHJvcHMub25TZWxlY3RBbHQoJ0dCJyl9XG4gICAgICAgIC8+XG4gICAgICA8L0J1dHRvbkNvbnRhaW5lcj5cbiAgICA8L2Rpdj5cbiAgKVxufVxuXG5leHBvcnQgZGVmYXVsdCBJbnRlcm5hdGlvbmFsUHJvbXB0Il19 */"
}); // prepping internation component


var InternationalPrompt = function InternationalPrompt(props) {
  return React.createElement("div", null, React.createElement(ErrorText, null, "Sorry, we don't support that country code yet! We recommend a U.S. (+1) or UK (+44) number for the best experience with international messaging"), React.createElement(ButtonContainer, null, React.createElement(Button, {
    text: "Try US (+1) Number",
    onClick: function onClick() {
      return props.onSelectAlt('US');
    }
  }), React.createElement(Button, {
    text: "Try UK (+44) Number",
    onClick: function onClick() {
      return props.onSelectAlt('GB');
    }
  })));
};

export default InternationalPrompt;
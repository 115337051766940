function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React, { useState } from 'react';
import Responsive from 'react-responsive';
import { parse, stringify } from 'querystring';
import styled from '@emotion/styled';
import Container from '../../../components/Container';
import Column from '../../../components/Column';
import BackButton from '../../../components/BackButton';
import Logo from '../../../components/Logo';
import TitleText from '../../../components/TitleText';
import SubTitleText from '../../../components/SubTitleText';
import SVGVector from '../../../components/SVGVector';
import CompanyList from '../components/CompanyList';
import Storage from '../../../services/Storage';
import companiesImg from '../../../assets/companies.png';

var Tablet = function Tablet(props) {
  return React.createElement(Responsive, _extends({}, props, {
    minWidth: 769
  }));
};

var LoginContainer = function LoginContainer(props) {
  if (!Storage.get('x-whistle-auth-token')) {
    props.history.replace({
      pathname: '/login',
      search: props.location.search
    });
    return React.createElement("div", null);
  }

  return React.createElement(Container, null, React.createElement(Tablet, null, React.createElement(Column, {
    bgImage: companiesImg
  }, React.createElement(BackButton, null))), React.createElement(Column, {
    backgroundColor: "white"
  }, React.createElement(Logo, null), React.createElement(Container, null, React.createElement(CompanyList, null))));
};

export default LoginContainer;
import _styled from "@emotion/styled-base";
import React from 'react';
import Logo from '../../components/Logo';

var Wrapper = _styled("div", {
  target: "enxoo2g0"
})(process.env.NODE_ENV === "production" ? {
  name: "gyb01r",
  styles: "height:100vh;display:flex;align-items:center;justify-content:center;flex-direction:column;"
} : {
  name: "gyb01r",
  styles: "height:100vh;display:flex;align-items:center;justify-content:center;flex-direction:column;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL3BhZ2VzL3NwbGFzaC1zY3JlZW4vU3BsYXNoU2NyZWVuLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUswQiIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9wYWdlcy9zcGxhc2gtc2NyZWVuL1NwbGFzaFNjcmVlbi5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5pbXBvcnQgTG9nbyBmcm9tICcuLi8uLi9jb21wb25lbnRzL0xvZ28nXG5cbmNvbnN0IFdyYXBwZXIgPSBzdHlsZWQuZGl2YFxuICBoZWlnaHQ6IDEwMHZoO1xuICAvKmJhY2tncm91bmQ6IGxpbmVhci1ncmFkaWVudCh0byBib3R0b20gcmlnaHQsICMwMEIzODYgMCUsICNmYmJkMDggNzUlKTsqL1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbmBcblxuY29uc3QgSW5mb1RleHQgPSBzdHlsZWQuZGl2YFxuICBmb250LWZhbWlseTogJ1JvYm90bycsc2VyaWY7XG4gIGZvbnQtd2VpZ2h0OiBib2xkO1xuICBjb2xvcjogIzRhNGE0YTtcbiAgbWFyZ2luLXRvcDogMXJlbTtcbmBcblxuY29uc3QgU3BsYXNoU2NyZWVuID0gKCkgPT4ge1xuICByZXR1cm4gKFxuICAgIDxXcmFwcGVyPlxuICAgICAgPExvZ28gLz5cbiAgICAgIDxJbmZvVGV4dD5Mb2FkaW5nIGluZm8uLi48L0luZm9UZXh0PlxuICAgIDwvV3JhcHBlcj5cbiAgKTtcbn1cbiBcbmV4cG9ydCBkZWZhdWx0IFNwbGFzaFNjcmVlbjsiXX0= */"
});

var InfoText = _styled("div", {
  target: "enxoo2g1"
})(process.env.NODE_ENV === "production" ? {
  name: "h6d24j",
  styles: "font-family:'Roboto',serif;font-weight:bold;color:#4a4a4a;margin-top:1rem;"
} : {
  name: "h6d24j",
  styles: "font-family:'Roboto',serif;font-weight:bold;color:#4a4a4a;margin-top:1rem;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL3BhZ2VzL3NwbGFzaC1zY3JlZW4vU3BsYXNoU2NyZWVuLmpzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQWMyQiIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9wYWdlcy9zcGxhc2gtc2NyZWVuL1NwbGFzaFNjcmVlbi5qcyIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCBSZWFjdCBmcm9tICdyZWFjdCdcbmltcG9ydCBzdHlsZWQgZnJvbSAnQGVtb3Rpb24vc3R5bGVkJ1xuXG5pbXBvcnQgTG9nbyBmcm9tICcuLi8uLi9jb21wb25lbnRzL0xvZ28nXG5cbmNvbnN0IFdyYXBwZXIgPSBzdHlsZWQuZGl2YFxuICBoZWlnaHQ6IDEwMHZoO1xuICAvKmJhY2tncm91bmQ6IGxpbmVhci1ncmFkaWVudCh0byBib3R0b20gcmlnaHQsICMwMEIzODYgMCUsICNmYmJkMDggNzUlKTsqL1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbmBcblxuY29uc3QgSW5mb1RleHQgPSBzdHlsZWQuZGl2YFxuICBmb250LWZhbWlseTogJ1JvYm90bycsc2VyaWY7XG4gIGZvbnQtd2VpZ2h0OiBib2xkO1xuICBjb2xvcjogIzRhNGE0YTtcbiAgbWFyZ2luLXRvcDogMXJlbTtcbmBcblxuY29uc3QgU3BsYXNoU2NyZWVuID0gKCkgPT4ge1xuICByZXR1cm4gKFxuICAgIDxXcmFwcGVyPlxuICAgICAgPExvZ28gLz5cbiAgICAgIDxJbmZvVGV4dD5Mb2FkaW5nIGluZm8uLi48L0luZm9UZXh0PlxuICAgIDwvV3JhcHBlcj5cbiAgKTtcbn1cbiBcbmV4cG9ydCBkZWZhdWx0IFNwbGFzaFNjcmVlbjsiXX0= */"
});

var SplashScreen = function SplashScreen() {
  return React.createElement(Wrapper, null, React.createElement(Logo, null), React.createElement(InfoText, null, "Loading info..."));
};

export default SplashScreen;
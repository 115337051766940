function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React, { useState, useEffect } from 'react';
import Responsive from 'react-responsive';
/** @jsx jsx */

import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import CONFIG from '@whistle/config';
import { parse, stringify } from 'querystring';
import Container from '../../../components/Container';
import Column from '../../../components/Column';
import Logo from '../../../components/Logo';
import Button from '../../../components/Button';
import TitleText from '../../../components/TitleText';
import SubTitleText from '../../../components/SubTitleText';
import Form from '../../../components/Form';
import ErrorText from '../../../components/ErrorText';
import Input from '../../../components/Input';
import PropertyCode from '../components/PropertyCode';
import choiceImg from '../../../assets/choice.png';
import CompanyService from '../../../services/Company';
import ChoiceService from '../../../services/Choice';
import GA from '../../../services/GoogleAnalytics';
var salesEmail = "<a href='mailto:sales@trywhistle.com'>sales@trywhistle.com</a>";
var supportEmail = "<a href='mailto:".concat(CONFIG.SUPPORT_MAIL, "'>").concat(CONFIG.SUPPORT_MAIL, "</a>");

var Tablet = function Tablet(props) {
  return jsx(Responsive, _extends({}, props, {
    minWidth: 769
  }));
};

var VerifyUserContainer = function VerifyUserContainer(props) {
  var params = parse(props.location.search.substring(1));

  var _useState = useState(''),
      _useState2 = _slicedToArray(_useState, 2),
      email = _useState2[0],
      setEmail = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      launchDate = _useState4[0],
      setLaunchDate = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      loading = _useState6[0],
      setLoading = _useState6[1];

  var _useState7 = useState(false),
      _useState8 = _slicedToArray(_useState7, 2),
      resending = _useState8[0],
      setResending = _useState8[1];

  var _useState9 = useState(params.code || ''),
      _useState10 = _slicedToArray(_useState9, 2),
      code = _useState10[0],
      setCode = _useState10[1];

  var _useState11 = useState(''),
      _useState12 = _slicedToArray(_useState11, 2),
      errorText = _useState12[0],
      setErrorText = _useState12[1];

  var _useState13 = useState(false),
      _useState14 = _slicedToArray(_useState13, 2),
      slugInvalid = _useState14[0],
      setSlugInvalid = _useState14[1];

  if (!params.email) {
    props.history.replace({
      pathname: '/register'
    });
    return jsx("div", null);
  }

  var createCompany = function createCompany(slug, companyName, roomCount, data) {
    CompanyService.create(slug, companyName, roomCount, data).then(function () {
      setLoading(false);
      props.history.push({
        pathname: "/sms",
        search: "?".concat(stringify(data))
      });
    }).catch(function (err) {
      console.log({
        err: err
      });
      setLoading(false); // error creating bucket

      setErrorText("Oops! Something went wrong... please send us an email ".concat(supportEmail));
      Storage.set('x-whistle-sso-token', '');
      Storage.set('x-whistle-auth-token', '');
      props.history.replace({
        pathname: '/register'
      });
    });
  };

  var getChoiceCompany = function getChoiceCompany(hotelId) {
    return ChoiceService.read(hotelId).then(function (res) {
      if (res.data.results.id) {
        return res.data.results;
      } else {
        return Promise.reject({
          message: "Invalid Choice property ID"
        });
      }
    });
  };

  var verify = function verify() {
    setLoading(true);
    GA.event(params.source, {
      category: 'ChoiceCode',
      label: 'Submit',
      action: 'Attempted to Verify'
    });
    var hotelId = code.toLowerCase(); // validate property code is not used as a slug

    CompanyService.validate(hotelId).then(function (res) {
      if (res.data.results.company) {
        // already exists
        setSlugInvalid(true);
      } else {
        setSlugInvalid(false); // fetch choice company info

        return getChoiceCompany(hotelId.toUpperCase()).then(function (data) {
          // create company
          params.company = hotelId;
          params.PMS = 'Choice Advantage';
          params.name = "".concat(data.name, " - ").concat(hotelId);
          params.rooms = data.guestRooms;
          params.country = data.country;
          params.city = data.city;
          params.state = data.state;
          params.zip_code = data.zipCode;
          params.address_line = data.address;
          params.timezone = data.timezone;
          params.phone = data.phone;
          params.choice_code = hotelId.toUpperCase();

          if (params.source === 'internal' || params.source === 'implementations') {
            params.email = email;
            params.launch_date = launchDate;
          }

          if (params.source === 'internal') {
            return createCompany(hotelId, data.name, data.guestRooms, params);
          } else {
            setLoading(false);
            props.history.push({
              pathname: "/plans",
              search: "?".concat(stringify(params))
            });
          }
        });
      }
    }).catch(function (err) {
      setLoading(false);

      if (err.message) {
        setErrorText(err.message);
      }
    }); // 
    // UserService.verify(params.email, code, params)
    //   .then(() => {
    //     setLoading(false)
    //     GA.event(params.source, {
    //       category: 'ChoiceCode',
    //       label: 'Submit',
    //       action: 'Verified Successfully'
    //     })
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     GA.event(params.source, {
    //       category: 'ChoiceCode',
    //       label: 'Submit',
    //       action: 'Verification Failed'
    //     })
    //     GA.error(err)
    //     const defaultMessage = 'Code expired or does not match. Please try again.'
    //     setLoading(false)
    //     if (err.response && err.response.data.message && err.response.data.message !== 'Server error') {
    //       setErrorText(err.response.data.message || defaultMessage)
    //     } else {
    //       setErrorText(defaultMessage)
    //     }
    //   })
  };

  var _onSubmit = function onSubmit(e) {
    e.preventDefault();
    verify();
  };

  useEffect(function () {
    if (code.length === 5) {
      // automatically submit code
      verify();
    }
  }, [code]);
  return jsx(Container, null, jsx(Tablet, null, jsx(Column, {
    bgImage: choiceImg
  })), jsx(Column, {
    backgroundColor: "white",
    verticalCenter: true
  }, jsx(Logo, null), jsx(TitleText, {
    center: true
  }, "Enter your Choice property ID"), jsx(Form, {
    onSubmit: function onSubmit(e) {
      return _onSubmit(e);
    }
  }, params.source === 'internal' || params.source === 'implementations' || params.source === 'whistle_plus_stripe' ? [jsx(Input, {
    hiddenLabel: true,
    label: 'Company Champion Email',
    id: 'email',
    placeholder: 'Company Champion Email',
    value: email,
    onChange: function onChange(e) {
      return setEmail(e.target.value);
    },
    key: "0"
  }), jsx(Input, {
    hiddenLabel: true,
    type: "date",
    label: 'Trial Start Date',
    id: 'trial',
    placeholder: 'Trial Start Date',
    value: launchDate,
    onChange: function onChange(e) {
      return setLaunchDate(e.target.value);
    },
    key: "1"
  })] : null, jsx(PropertyCode, {
    value: code,
    onChange: function onChange(val) {
      setCode(val);
      setErrorText('');
    }
  }), jsx(Button, {
    id: "verify-submit",
    text: "Next >>",
    disabled: code.length !== 5 || loading || resending,
    center: true,
    loading: loading || resending
  })), slugInvalid ? jsx(ErrorText, {
    center: true
  }, "\u274C This property code is already in use. Please send us an email ", salesEmail) : null, jsx(ErrorText, {
    center: true
  }, errorText)));
};

export default VerifyUserContainer;
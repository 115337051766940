import _styled from "@emotion/styled-base";

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from 'react';
import logoImg from '../assets/logo.svg';

var Logo = _styled("img", {
  target: "e1gf3nc80"
})(function (props) {
  return _objectSpread({}, props.style, {
    width: '100%',
    maxWidth: '170px'
  });
}, process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvTG9nby5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJYSIsImZpbGUiOiIvaG9tZS9jaXJjbGVjaS9wcm9qZWN0L3NyYy9jb21wb25lbnRzL0xvZ28uanMiLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgUmVhY3QgZnJvbSAncmVhY3QnXG5pbXBvcnQgc3R5bGVkIGZyb20gJ0BlbW90aW9uL3N0eWxlZCdcbmltcG9ydCBsb2dvSW1nIGZyb20gJy4uL2Fzc2V0cy9sb2dvLnN2ZydcblxuY29uc3QgTG9nbyA9IHN0eWxlZC5pbWcocHJvcHMgPT4gKHtcbiAgLi4ucHJvcHMuc3R5bGUsXG4gIHdpZHRoOiAnMTAwJScsXG4gIG1heFdpZHRoOiAnMTcwcHgnXG59KSlcblxuY29uc3QgTG9nb1dyYXBwZXIgPSBzdHlsZWQuZGl2YFxuICB0ZXh0LWFsaWduOiBjZW50ZXI7XG5gXG5cbmNvbnN0IFN0eWxlZExvZ28gPSAocHJvcHMpID0+IHtcbiAgcmV0dXJuIChcbiAgICA8TG9nb1dyYXBwZXIgc3R5bGU9e3Byb3BzLnN0eWxlfT5cbiAgICAgIDxMb2dvIHNyYz17bG9nb0ltZ30gLz5cbiAgICA8L0xvZ29XcmFwcGVyPlxuICApXG59XG5cbmV4cG9ydCBkZWZhdWx0IFN0eWxlZExvZ28iXX0= */");

var LogoWrapper = _styled("div", {
  target: "e1gf3nc81"
})(process.env.NODE_ENV === "production" ? {
  name: "xi606m",
  styles: "text-align:center;"
} : {
  name: "xi606m",
  styles: "text-align:center;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9ob21lL2NpcmNsZWNpL3Byb2plY3Qvc3JjL2NvbXBvbmVudHMvTG9nby5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFVOEIiLCJmaWxlIjoiL2hvbWUvY2lyY2xlY2kvcHJvamVjdC9zcmMvY29tcG9uZW50cy9Mb2dvLmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0J1xuaW1wb3J0IHN0eWxlZCBmcm9tICdAZW1vdGlvbi9zdHlsZWQnXG5pbXBvcnQgbG9nb0ltZyBmcm9tICcuLi9hc3NldHMvbG9nby5zdmcnXG5cbmNvbnN0IExvZ28gPSBzdHlsZWQuaW1nKHByb3BzID0+ICh7XG4gIC4uLnByb3BzLnN0eWxlLFxuICB3aWR0aDogJzEwMCUnLFxuICBtYXhXaWR0aDogJzE3MHB4J1xufSkpXG5cbmNvbnN0IExvZ29XcmFwcGVyID0gc3R5bGVkLmRpdmBcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xuYFxuXG5jb25zdCBTdHlsZWRMb2dvID0gKHByb3BzKSA9PiB7XG4gIHJldHVybiAoXG4gICAgPExvZ29XcmFwcGVyIHN0eWxlPXtwcm9wcy5zdHlsZX0+XG4gICAgICA8TG9nbyBzcmM9e2xvZ29JbWd9IC8+XG4gICAgPC9Mb2dvV3JhcHBlcj5cbiAgKVxufVxuXG5leHBvcnQgZGVmYXVsdCBTdHlsZWRMb2dvIl19 */"
});

var StyledLogo = function StyledLogo(props) {
  return React.createElement(LogoWrapper, {
    style: props.style
  }, React.createElement(Logo, {
    src: logoImg
  }));
};

export default StyledLogo;